/**
 * Customer Recoil store
 */

import { atom, selector } from 'recoil'
import { getAge } from '@/utils/date'
import { BrowserDatabase } from '@/utils/BrowserDatabase'
import { CUSTOMER } from '@/utils/BrowserDatabase/type'
import { ONE_MONTH_IN_SECONDS, ONE_YEAR_IN_SECONDS } from '@/utils/BrowserDatabase/lifetime'
import countriesData from '@/config/legal_age.json'

/* CustomerState */
export const CUSTOMER_KEY = 'customerState'
export const CUSTOMER_MANAGER = 'customerManager'
export const HAS_VALID_AGE_MANAGER = 'hasValidAgeManager'

// STATE
export const customerState = atom({
  key: CUSTOMER_KEY,
  default: BrowserDatabase.getItem(CUSTOMER)
})

// MANAGER
/* CUSTOMER */
export const customerManager = selector({
  key: CUSTOMER_MANAGER,
  get: ({ get }) => get(customerState),
  set: ({ set, get }, newValue) => {
    const customerData = get(customerState)
    const data = { ...customerData, ...newValue }
    const expiration = (newValue?.remember_me || customerData?.remember_me) ? ONE_YEAR_IN_SECONDS : ONE_MONTH_IN_SECONDS
    BrowserDatabase.setItem(data, CUSTOMER, expiration)
    set(customerState, data)
  }
})

/* HAS VALID AGE */
export const hasValidAgeManager = selector({
  key: HAS_VALID_AGE_MANAGER,
  get: ({ get }) => {
    const customerData = get(customerState)

    if (customerData) {
      const minAge = countriesData.countries.find((c) => c.label === customerData.country).legal_age
      return !!customerData?.birth && getAge(customerData?.birth) >= minAge
    }

    return false
  }
})

/* CustomerState */
