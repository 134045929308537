import { forwardRef, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState} from 'recoil'
import { headerHeightState, stickyHeightState} from '@/recoil/header'
import * as S from './style'

const Section = forwardRef(({ name = "name", id = "1", children }, ref) => {
    const headerHeight = useRecoilValue(headerHeightState)
    const stickyHeight = useRecoilValue(stickyHeightState)


    return (
        <S.Section offsetScroll={headerHeight + stickyHeight} name={name} id={id} ref={ref}>
          {children}
        </S.Section>
    )
  })

export default Section