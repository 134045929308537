import styled from '@emotion/styled'

import { LinkContent } from "@/components/Link/style"
import { LinkLabel } from "@/components/Link/style"


import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightVanilla};
  border-radius: 10px;
  position: relative;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Input = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => `${theme.colors.grey}30`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mediaMax.xs} {
    width: 1.6rem;
    height: 1.6rem;
  }

  &:before {
    width: 15px;
    height: 15px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background-color: ${({ theme }) => theme.colors.brown};
    transform: translate(-50%, -50%);
    transition: opacity ease-out 300ms;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`

export const Label = styled.span`
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.gray};
  transition: opacity ease-out 0.3s;

  i{
    font-style: italic;
    font-size: 1rem;
  }

  ${mediaMax.sm}{
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  p {
    margin-bottom: .5rem;
  }

  strong {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray};
  }

  a {
    width: fit-content;
    display: inline-block;
    color: ${({ theme }) => theme.colors.gray};
    text-decoration: underline;
    text-transform: initial;
  }


  ${LinkLabel}{
    text-transform: none;
    color: ${p => p.theme.colors.gray};
    font-size: inherit;
    &:after{
      display: none
    }
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  margin-left: 13px;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  ${({ error, theme }) =>
    error && `${Label} { color: ${theme.colors.redError};}`}
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  transition: background-color ease-out 0.3s;

  ${({ error, theme }) =>
    error &&
    `
    ${Label} {
      color: ${theme.colors.redError};
    }

    ${Input} {
      border: 1px solid ${theme.colors.redError};
    }
  `}
`
