import React, { useContext } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { marketCodeManager, fallbackMarketCodeState, marketCodesListState } from '@/recoil/marketCode'
import { MarketCodeContext } from '@/context/MarketContext'

export const LocalizedGatsbyLink = (props) => {
  const { to } = props
  const pageMarketCode = useContext(MarketCodeContext)
  const marketCode = useRecoilValue(marketCodeManager)
  const fallbackMarketCode = useRecoilValue(fallbackMarketCodeState)
  const marketCodesList = useRecoilValue(marketCodesListState)
  const hasMarketCodeInURL = to && marketCodesList.includes(to.split('/')[1])
  const URLMarketCode = typeof window !== 'undefined' ? marketCode : pageMarketCode
  const URL = hasMarketCodeInURL ? to : `/${URLMarketCode}${to}`

  return (
    <Link
      { ...props }
      hrefLang={ URLMarketCode === fallbackMarketCode ? 'x-default' : URLMarketCode }
      to={ URL.replace(/\/?(\?|#|$)/, '/$1') }
    />
  )
}

LocalizedGatsbyLink.propTypes = {
  to: PropTypes.string
}

export default LocalizedGatsbyLink
