import styled from '@emotion/styled'

export const LinkWrapper = styled.div`
  width: 100%;
`

export const LinkContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LinkLabel = styled.span`
  text-decoration: none;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey};
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`
