/**
 * Is ecommerce Recoil store
 */
import { atom } from 'recoil'

export const IS_ECOMMERCE_KEY = 'isEcommerce'

export const isEcommerceState = atom({
  key: IS_ECOMMERCE_KEY,
  default: false
})
