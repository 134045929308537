import React, { useRef, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import { useLocation } from '@reach/router'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { customerManager } from '@/recoil/customer'
import { marketCodeManager } from '@/recoil/marketCode'
import { countryState } from '@/recoil/ageGate'

import moment from 'moment'

import { checkFormData } from '@/utils/forms'
import { getAge } from '@/utils/date'
import countriesData from '@/config/legal_age.json'
import { AGE_GATE } from '@/utils/gtmEvents'

import SelectField from '@/components/Forms/Inputs/Select'
import DateField from '@/components/Forms/Inputs/Date'
import CheckboxField from '@/components/Forms/Inputs/Checkbox'
import Button from '@/components/Button'

import {
  Container,
  LabelWrapper,
  Label,
  FormWrapper,
  InputWrapper,
  CheckBoxWrapper,
  SubmitWrapper,
  ErrorWrapper,
  ErrorLabel
} from './style'
import PropTypes from "prop-types";
import AgeGate from "@/components/AgeGate";

const AgeForm = ({ onSuccess = () => {} }) => {
  const setCustomerValue = useSetRecoilState(customerManager)
  const [marketCode, setMarketCode] = useRecoilState(marketCodeManager)
  const [countryName, setCountryName] = useRecoilState(countryState)
  const [error, setError] = useState(false)
  const selectRef = useRef(null)
  const dateRef = useRef(null)
  const checkBoxRef = useRef(null)
  const [isUsa, setIsUsa] = useState(false)
  const { pathname, origin } = useLocation()
  const [defaultCountry, setDefaultCountry] = useState(null)

  const getUserCountry = async () => {
    const response = await axios.get(origin)

    if (response.headers['x-aka-country']) {
      const country = countriesData.countries.find((country) => country.countryCode === response.headers['x-aka-country'])
      if (country) setDefaultCountry(country)
    }
  }

  const validateDate = (value, country) => {
    let valid = false
    const minAge = countriesData.countries.find((c) => c.label === country).legal_age

    if (minAge >= 0 && value.length === 10) {
      const age = getAge(value, isUsa)
      valid = (age >= minAge)
    }

    return valid
  }

  useEffect(() => {
    getUserCountry()

    const event = {
      country: 'WW',
      page_type: 'Age Gate',
      page_top_category: 'Age Gate'
    }
    if (typeof window !== 'undefined' && window.dataLayer && (dataLayer[dataLayer.length - 1].country !== event.country || dataLayer[dataLayer.length - 1].page_type !== event.page_type)) {
      window.dataLayer.push(event)
    }
  }, [])

  const handleSubmit = () => {
    const formData = checkFormData([selectRef, dateRef, checkBoxRef])

    if (!formData.errors.length) {
      const { data: { country, birth: dateValue } } = formData
      const isDateValid = validateDate(dateValue, country)
      const countryData = countriesData.countries.find((c) => c.label === country)

      if (countryData.market === 'en-us') {
        formData.data.birth = moment(dateValue).format('DD/MM/YYYY')
      }
      if (!countryData.is_authorized || !isDateValid) {
        setTimeout(() => {
          navigate('https://www.wineinmoderation.eu/')
        }, 5000)
      }
      if (!countryData.is_authorized) {
        setError('country')
      } else {
        if (isDateValid) {
          // gtm

          setCustomerValue({ ...formData.data, countryCode: countryData.countryCode })
          setMarketCode(countryData.market)

          window.dataLayer && window.dataLayer.push({
            event: AGE_GATE,
            request_country: countryData.countryCode,
            age_gate_type: 'Pass' // 'Show' || 'Pass'
          })

          const splittedPathname = pathname.split('/')
          const marketCodeFromPathname = splittedPathname[1]

          if (countryData.market !== marketCodeFromPathname) {
            // splittedPathname[1] = countryData.market
            // navigate(splittedPathname.join('/'))
            const baseUrl = marketCodeFromPathname === '' ? window.location.origin + '/' : window.location.origin
            window.location = `${baseUrl}${window.location.pathname.replace(marketCodeFromPathname, countryData.market)}`
          } else {
            if (onSuccess) {
              onSuccess()
            }
          }
        } else {
          dateRef.current.triggerError(true)
          setError('age')
        }
      }
    }
  }

  const onCountryChange = (value) => {
    setCountryName(value)
    setError(false)
    const countryData = countriesData.countries.find((c) => c.label === value)
    window.dataLayer && window.dataLayer.push({
      event: AGE_GATE,
      request_country: countryData ? countryData.countryCode : countryName,
      age_gate_type: 'Show'
    })
  }

  useEffect(() => {
    setIsUsa(countryName === 'USA')
  }, [countryName])

  return (
    <Container>
      <LabelWrapper>
        <Label>
          Please indicate your country/region and your date of birth
        </Label>
      </LabelWrapper>
      <FormWrapper>
        <InputWrapper>
          <SelectField
            ref={ selectRef }
            label='Select your country'
            name='country'
            nameSet='Countries'
            separation
            list={ countriesData.countries.map((country) => country.label) }
            isRequired
            onChange={ onCountryChange }
            withQuickSearch
            defaultValue={ defaultCountry?.label || '' }
          />
        </InputWrapper>
        <InputWrapper>
          <DateField
            ref={ dateRef }
            label={ isUsa ? 'MM/DD/YYYY' : 'DD/MM/YYYY' }
            name='birth'
            isRequired
            onChange={ () => setError(false) }
            isUSA={ isUsa }
          />
        </InputWrapper>
        <CheckBoxWrapper>
          <CheckboxField
            ref={ checkBoxRef }
            name='remember_me'
            onChange={ () => setError(false) }
          >
            Remember me <i>(do not tick the box if your device is shared)</i>
          </CheckboxField>
        </CheckBoxWrapper>
        <SubmitWrapper>
          <Button type='green-alt' onClick={ () => handleSubmit() }>
            ENTER
          </Button>
        </SubmitWrapper>
        <ErrorWrapper active={ error }>
          {error === 'age' && (
            <ErrorLabel>
              Unfortunately you cannot enter this website as you are not of legal drinking and purchasing age.
              Would you like more information on <a href='http://www.wineinmoderation.eu'>responsible drinking</a>?
            </ErrorLabel>
          )}
          {error === 'country' && (
            <ErrorLabel>
              Due to regulations in your country, you cannot access this website.
            </ErrorLabel>
          )}
        </ErrorWrapper>
      </FormWrapper>
    </Container>
  )
}

AgeForm.propTypes = {
  onSuccess: PropTypes.func
}

export default AgeForm
