import {
  useRef,
  useState,
  useEffect
} from 'react'

import { useRecoilValue } from 'recoil'

import { stickyHeightState, headerHeightState } from '@/recoil/header'

import Section from '@/components/SectionsNav/Section'
import isInViewport from '../utils/isInViewport'

/**
 * Custom Hook to get Previous value on component update
 *
 * @param {Any} value
 * @returns {Any}
 */
export const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

/**
 * Custom Hook triggering on componentDidMount only
 *
 * @param {Function} effect
 * @param {Array<Any>} dependencies
 */
export const useMountEffect = (effect, dependencies = []) => {
  const ref = useRef(true)

  useEffect(() => {
    if (ref.current) {
      ref.current = false
      effect()
    }
  }, dependencies)
}

/**
 * Custom Hook triggering on componentDidUpdate only
 *x
 * @param {Function} effect
 * @param {Array<Any>} dependencies
 */
export const useUpdateEffect = (effect, dependencies = []) => {
  const ref = useRef(true)

  useEffect(() => {
    if (ref.current) {
      ref.current = false
    } else {
      effect()
    }
  }, dependencies)
}

function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

// Hook window size
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  })

  function getSize () {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  useEffect(() => {
    // Handler to call on window resize
    // const handleResizeDebounced = debounce(function handleResize () {
    //   setWindowSize(getSize())
    // }, 250)
    const handleResize = () => {
      setWindowSize(getSize())
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export const useIsFocused = (ref) => {
  const [focus, setFocus] = useState(false)

  const handleFocus = () => {
    setFocus(true)
  }

  const handleBlur = () => {
    setFocus(false)
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('focus', handleFocus)
      ref.current.addEventListener('blur', handleBlur)
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('focus', handleFocus)
        ref.current.removeEventListener('blur', handleBlur)
      }
    }
  }, [ref])

  return [focus]
}

export const useIsMounted = () => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => isMounted.current = false
  }, [])
  return isMounted
}

/**
 * Hook that watches when the sections are in viewport and updates the active section
 * @param {Array} sectionsRef - An array of refs to the sections.
 * @param {Object} options - An object containing the options.
 * @return {Array} - An array containing the Section (HTMLElement), the sections state and a setter.
 */

export function useWatchSections (
  sectionsRef,
  options = {
    offsetTop: 0
  }) {
  const [sections, setSections] = useState([])
  const [offset, setOffset] = useState(0)
  const headerHeight = useRecoilValue(headerHeightState)
  const stickyHeight = useRecoilValue(stickyHeightState)

  function setter (id, isActive) {
    const sectionAlreadySet = sections.find(section => section.id === id && section.isActive === isActive)
    if (sectionAlreadySet) return // prevent useless re-rendering

    setSections((state) => {
      return state.map((item) => {
        if (item.id === id) {
          return { ...item, isActive }
        } else {
          return { ...item, isActive: false }
        }
      })
    })
  }

  function onScroll () {
    if (window.innerWidth <= 768) return
    let noSectionsInVp = true
    sections.forEach((section) => {
      if (section.ref.current && isInViewport(section.ref.current, offset + 25)) {
        noSectionsInVp = false
        return setter(section.id, true)
      }
    })
    if (noSectionsInVp) {
      const noSectionsActive = !sections.find(section => section.isActive)
      if (noSectionsActive) return // prevent useless re-rendering
      setSections(sections.map((section) => ({ ...section, isActive: false })))
    }
  }

  useEffect(() => {
    if (sections.length <= 0) return
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [sections])

  useEffect(() => {
    const state = sectionsRef.map((ref) => {
      return {
        isActive: false,
        ref: ref,
        name: ref.current?.getAttribute('name'),
        id: ref.current?.getAttribute('id')
      }
    })
    setSections(state)
  }, [])

  useEffect(() => {
    setOffset(headerHeight + stickyHeight)
  }, [headerHeight, stickyHeight])

  return [Section, sections, setter]
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}
