/**
 * Age Gate Recoil store
 */
import { atom } from 'recoil'

export const COUNTRY_KEY = 'USER_COUNTRY'

export const countryState = atom({
  key: COUNTRY_KEY,
  default: ''
})
