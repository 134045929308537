/**
 * Market code Recoil store
 */
import { atom, selector } from 'recoil'
import { isEcommerceState } from '@/recoil/isEcommerce'
import { BrowserDatabase } from '@/utils/BrowserDatabase'

export const MARKET_CODE_LIST_KEY = 'marketCodeList'
export const ECOMMERCE_MARKET_CODE_LIST_KEY = 'ecommerceMarketCodeList'
export const FALLBACK_MARKET_CODE_KEY = 'fallbackMarketCode'
export const MARKET_CODE_KEY = 'marketCode'
export const MARKET_CODE_MANAGER = 'marketCodeManager'

export const fallbackMarketCode = 'en-ww'

export const marketCodesListState = atom({
  key: MARKET_CODE_LIST_KEY,
  default: []
})

export const ecommerceMarketCodesListState = atom({
  key: ECOMMERCE_MARKET_CODE_LIST_KEY,
  default: []
})

export const fallbackMarketCodeState = atom({
  key: FALLBACK_MARKET_CODE_KEY,
  default: fallbackMarketCode
})

export const marketCodeState = atom({
  key: MARKET_CODE_KEY,
  default: BrowserDatabase.getItem(MARKET_CODE_KEY) || fallbackMarketCode
})

export const marketCodeManager = selector({
  key: MARKET_CODE_MANAGER,
  get: ({ get }) => get(marketCodeState),
  set: ({ set, get }, newValue) => {
    set(isEcommerceState, get(ecommerceMarketCodesListState).includes(newValue))
    set(marketCodeState, newValue)
    BrowserDatabase.setItem(newValue, MARKET_CODE_KEY)

    const oneTrustLink = document.querySelector('.privacy-notice-link')

    if (oneTrustLink) {
      const url = oneTrustLink.getAttribute('href').split('/')
      url[3] = newValue
      oneTrustLink.setAttribute('href', url.join('/'))
    }
  }
})
