/**
 * Layout Recoil store
 */
import { atom } from 'recoil'

/* isMobileState */
export const IS_MOBILE_KEY = 'IS_MOBILE_KEY'

export const isMobileState = atom({
  key: IS_MOBILE_KEY,
  default: false
})

/* isMobileState */

/* isTabletState */
export const IS_TABLET_KEY = 'IS_TABLET_KEY'

export const isTabletState = atom({
  key: IS_TABLET_KEY,
  default: false
})

/* isTabletState */
