import * as React from 'react'
import PropTypes from 'prop-types'

import Link from '@/components/LocalizedGatsbyLink'
import { useLocation } from '@reach/router'
import { CLICK_ON_CTA } from '@/utils/gtmEvents'
import isExternalLink from '@/utils/isExternalLink'
import isPdf from '@/utils/isPdf'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'

import {
  ButtonWrapper,
  ButtonContent,
  ButtonLabel
} from './style'

const Button = (props) => {
  const {
    type = 'green',
    to = '',
    ctaTracking = true,
    onClick = () => {},
    target = '_self',
    children,
    className = ''
  } = props

  const isMobile = useRecoilValue(isMobileState)
  const location = useLocation()
  const isAnchor = to && to.startsWith('#')

  const handleClick = (e) => {
    if (ctaTracking) {
      window.dataLayer && window.dataLayer.push({
        event: CLICK_ON_CTA,
        site_location: toCapitalize((location.pathname.split('/').splice(2)[0] === '' || location.pathname.split('/').splice(2)[0] === undefined) ? 'Home' : location.pathname.split('/').splice(2)[0].replace('-', ' ')),
        cta_name: children
      })
    }
    onClick(e)
  }

  const toCapitalize = (word) => {
    const capitalizeWord = word.split(' ')
    for (let i = 0; i < capitalizeWord.length; i++) {
      capitalizeWord[i] = capitalizeWord[i][0].toUpperCase() + capitalizeWord[i].substr(1)
    }

    capitalizeWord.join(' ')
    return capitalizeWord.toString().replace(/,/g, ' ')
  }

  const IsEmailInString = (text) => {
    const re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

    return re.test(text)
  }

  return (
    <ButtonWrapper
      { ...props }
      { ...((to && (isExternalLink(to) || isAnchor || isPdf(to)) && !IsEmailInString(to)) && { as: 'a', href: to, target: target, onClick: handleClick }) }
      { ...((to && (!isExternalLink(to) && !isAnchor && !isPdf(to)) && !IsEmailInString(to)) && { as: Link, to, target: target, onClick: handleClick }) }
      { ...(to && !isExternalLink(to) && !isAnchor && IsEmailInString(to)) && { as: 'a', href: to, target: target, onClick: handleClick } }
      { ...(!to.length && { onClick }) && { as: 'button', onClick: handleClick } }
      { ...(!to.length) && { as: 'button', type: 'submit', onClick: handleClick } }
      className={ `${type} ${className} ${isMobile && children?.length > 20 ? 'wrapText' : ''}` }
      title={ children }
    >
      <ButtonContent>
        <ButtonLabel>
          { children }
        </ButtonLabel>
      </ButtonContent>
    </ButtonWrapper>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string
}

export default Button
