import { useUpdateEffect } from '@/hook'
import isBrowser from '@/utils/isBrowser'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import PropTypes from 'prop-types'

import Cleave from 'cleave.js/react'

import {
    Container, InputWrapper,
    Label, Wrapper
} from './style'

const Date = forwardRef(({ label = '', isRequired = false, onChange = null, validator = null, name = '', isUSA = false }, ref) => {
  // LOCAL VALUE
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)

  // ON COMPONENT UPDATE
  useUpdateEffect(() => {
    checkStatus()
    if (onChange) { onChange(value) }
  }, [value])

  // PRIVATE METHODS
  const handleChange = (newVal) => {
    setValue(newVal)
  }

  const checkStatus = () => {
    let err = false

    if (isRequired) {
      if (!value.length) { err = true }
      if (value.length && validator) {
        err = validator(value)
      }
    }

    setError(err)

    return err
  }

  // PUBLIC METHODS
  useImperativeHandle(ref, () => ({
    isRequired: () => isRequired,
    getName: () => name,
    getValue: () => value,
    hasError: () => error,
    triggerError: (e) => setError(e),
    isValid: () => !checkStatus()
  }))

  return (
    <Container>
      <Wrapper error={ error }>
        <InputWrapper>
          {isUSA && (
            <>
              <Label htmlFor="birthdate">Enter your date of birth</Label>
              <Cleave
                id="birthdate"
                title="birthdate"
                placeholder={ label }
                options={ {
                  date: true,
                  datePattern: ['m', 'd', 'Y']
                } }
                onChange={ (e) => handleChange(e.target.value) }
              />
            </>
          )}
          {!isUSA && (
            <>
              <Label htmlFor="birthdate">Enter your date of birth</Label>
              <Cleave
                id="birthdate"
                title="birthdate"
                placeholder={ label }
                options={ {
                  date: true,
                  datePattern: ['d', 'm', 'Y']
                } }
                onChange={ (e) => handleChange(e.target.value) }
              />
            </>
          )}
        </InputWrapper>
      </Wrapper>
    </Container>
  )
})

Date.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  name: PropTypes.string,
  isUSA: PropTypes.bool
}

export default Date
