export const SOCIAL_FOLLOW = 'social_follow'
export const HEADER_CLICK = 'header_click'
export const FOOTER_CLICK = 'footer_click'
export const CLICK_ON_CTA = 'click_on_cta'
export const NL_POPIN_CLICK = 'nl_popin_click'
export const NL_SUBSCRIPTION = 'nl_subscription'
export const BOOK_NOW = 'book_now'
export const AGE_GATE = 'age_gate'
export const CONTACT_FORM = 'generate_lead'
export const PRODUCT_FEATURE = 'product_feature'
export const STORE_LOCATOR = 'storeLocator'
export const VIEW_ITEM = 'view_item'
export const ADD_TO_CART = 'add_to_cart'
export const WINE_FINDER_CLICK = 'wine_finder_click'
export const WINE_FINDER_RESULTS = 'wine_finder_results'
export const WINE_FINDER_CHOICES = 'wine_finder_choices'
export const VIEW_SEARCH_RESULTS = 'view_search_results'
