/**
 * Layout Recoil store
 */
import { atom } from 'recoil'

/* headerHeight state */
export const HEADER_HEIGHT = 'HEADER_HEIGHT'
/* stickyHeight state */
export const STICKY_HEIGHT = 'STICKY_HEIGHT'
/* subNavHeight state */
export const SUB_NAV_HEIGHT = 'SUB_NAV_HEIGHT'
/* current SubNavId */
export const SUBNAV_ID = 'SUBNAV_ID'

export const HEADER_HEIGHT_CONFIG = {
  SMALL: 64,
  NORMAL: 100
}

export const headerHeightState = atom({
  key: HEADER_HEIGHT,
  default: HEADER_HEIGHT_CONFIG.NORMAL
})

/* stickyHeight state */

export const stickyHeightState = atom({
  key: STICKY_HEIGHT,
  default: 0
})

/* subNavHeight state */

export const subNavHeightState = atom({
  key: SUB_NAV_HEIGHT,
  default: 0
})

/* headerIsOpen state */
export const subNavIdState = atom({
  key: SUBNAV_ID,
  default: null
})
