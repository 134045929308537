import styled from '@emotion/styled'

import { mediaMax, mediaMin } from '@/styles/mixins'

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonLabel = styled.span`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-weight: bold;
  transition: color ease-out 0.3s;
  letter-spacing: 0.1rem;

  ${mediaMax.xs} {
    font-size: 1.2rem;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 1.8rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.lightGreen};
  text-decoration: none;
  transition: background-color ease-out 0.3s;

  ${mediaMax.xs} {
    padding: 2rem 5.2rem;
  }

  &.wrapText {
    ${mediaMax.xs} {
      padding: 1.5rem 2.7rem;
    }
    ${ButtonLabel} {
      white-space: normal;
      line-height: 1.7rem;
    }
  }

  &.noBorder {
    border: none;
  }

  &.custom {
    border: 1px solid ${({ color }) => color};
    background-color: ${({ color }) => color};

    ${ButtonLabel} {
      color: ${({ theme }) => theme.colors.white};
    }


    ${mediaMin.md}{
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};

        ${ButtonLabel} {
          color: ${({ color }) => color};
        }
      }
    }
  }

  &.green,
  &.green-alt {
    background-color: ${({ theme }) => theme.colors.lightGreen};

    ${ButtonLabel} {
      color: ${({ theme }) => theme.colors.white};
    }

    ${mediaMin.md}{
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};

        ${ButtonLabel} {
          color: ${({ theme }) => theme.colors.lightGreen};
        }
      }
    }
  }

  &.green-alt {
    ${mediaMin.md}{
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightVanilla};
      }
    }
  }

  &.green-alt3 {
    border-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.lightGreen3};

    ${ButtonLabel} {
      color: ${({ theme }) => theme.colors.white};
    }

    ${mediaMin.md}{
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};

        ${ButtonLabel} {
          color: ${({ theme }) => theme.colors.lightGreen3};
        }
      }
    }
  }

  &.white {
    background-color: ${({ theme }) => theme.colors.white};

    ${ButtonLabel} {
      color: ${({ theme }) => theme.colors.lightGreen};
    }
    ${mediaMin.md}{
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightGreen};

        ${ButtonLabel} {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }

  &.brown {
    background-color: ${({ theme }) => theme.colors.brown};
    border: 1px solid ${({ theme }) => theme.colors.white};

    ${ButtonLabel} {
      color: ${({ theme }) => theme.colors.white};
    }

    ${mediaMin.md}{
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};

        ${ButtonLabel} {
          color: ${({ theme }) => theme.colors.brown};
        }
      }
    }

  }

  &.underline {
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    color: ${({ theme }) => theme.colors.grey};
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    background: none;
    border: none;
    padding: 0;
    width: auto;

    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: ${({ theme }) => theme.colors.grey};
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }
`
