import PropTypes from 'prop-types'
import * as React from 'react'

import IcomoonReact from 'icomoon-react'

import iconSet from '@/icons/icons.json'

const Icon = ({
  id,
  nameSet,
  name,
  title = null,
  ariaLabel,
  size = 16,
  color = '#000000',
  link
}) => {
  if (link) {
    return (
      <a target='_blank' href={ link } title={ name } rel='noreferrer'>
        <IcomoonReact
          id={ id }
          className={ `icon-${name}` }
          iconSet={ iconSet }
          size={ size }
          color={ color }
          icon={ name }
          name={ nameSet }
          aria-label={ ariaLabel }
        />
        <span>{ title || '(opens in a new tab)' }</span>
      </a>
    )
  } else {
    return (
      <IcomoonReact
        id={ id }
        className={ `icon-${name}` }
        iconSet={ iconSet }
        size={ size }
        color={ color }
        icon={ name }
        name={ nameSet }
        aria-label={ ariaLabel }
      />
    )
  }
}

Icon.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameSet: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  link: PropTypes.string
}

export default Icon
