/**
 * @param {Array} fields
 * @returns {Object}
 */
export const checkFormData = (fields = []) => {
  if (!fields.length) {
    return {}
  }

  const form = { data: {}, errors: [] }

  for (let i = 0; i < fields.length; i++) {
    const input = fields[i].current

    if (!input) { continue }

    const name = input.getName()
    const value = input.getValue()
    const isValid = input.isValid()

    form.data[name] = value

    if (!isValid) {
      form.errors = [...form.errors, name]
    }
  }

  return form
}
