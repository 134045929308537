import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6rem;
  background-color: ${(props) => props.theme.colors.lightVanilla};
  border-radius: 10px;

  ${mediaMax.md} {
    padding: 4rem;
  }
  
  ${mediaMax.xs}{
    padding: 3rem;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;

  ${mediaMax.xs} {
    margin-bottom: 2.4rem;
  }
`

export const Label = styled.span`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.green};
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  ${mediaMax.xs} {
    font-size: 1.4rem;
  }
`

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`

export const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 24px;

  ${mediaMax.xs} {
    margin-top: 1.4rem;
    margin-bottom: 2rem;
  }
`

export const SubmitWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;

  transition: opacity ease-out 300ms;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  opacity: ${({ active }) => (active ? 1 : 0)};

  ${mediaMax.xs} {
    width: 100%;
  }
`

export const ErrorLabel = styled.span`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.redError};
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: center;
`
