import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightVanilla};
  border-radius: 10px;
  position: relative;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ error, theme }) => error ? theme.colors.redError : `${theme.colors.grey}30`};;
  text-decoration: none;
  transition: background-color ease-out 0.3s;

  input {
    color: ${({ theme }) => theme.colors.redError};

    &::placeholder {
      color: ${({ theme }) => theme.colors.redError};
    }
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    padding: 21px 67px;
    appearance: none;
    text-align: center;
    outline: none;
    border: none;
    background: none;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    color: ${({ theme }) => theme.colors.brown};

    ${mediaMax.xs} {
      padding: 1.4rem 67px;
    }

    &::placeholder {
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-family: ${(props) => props.theme.fonts.sansSerif};
      color: ${({ theme }) => theme.colors.grey};
    }

    &:focus {
      appearance: none;
      border: none;
      outline: none;
    }
  }
`

export const Label = styled.label`
  display: none;
`
