import React, { useImperativeHandle, forwardRef, useState } from 'react'

import PropTypes from 'prop-types'

import {
  Container,
  Wrapper,
  InputWrapper,
  Input,
  LabelWrapper,
  Label
} from './style'

import { useUpdateEffect } from '@/hook'

const Checkbox = forwardRef(({ isRequired = false, onChange = null, validator = null, name = '', children }, ref) => {
  // LOCAL VALUE
  const [value, setValue] = useState(false)
  const [error, setError] = useState(false)

  // ON COMPONENT UPDATE
  useUpdateEffect(() => {
    if (onChange) { onChange(value) }
  }, [value])

  // PRIVATE METHODS
  const handleChange = () => {
    setValue(!value)
    setError(false)
    if (onChange) { onChange(!value) }
  }

  const checkStatus = () => {
    let err = false

    if (isRequired) {
      if (!value) { err = true }
      if (value && validator) {
        err = validator(value)
      }
    }

    setError(err)

    return err
  }

  // PUBLIC METHODS
  useImperativeHandle(ref, () => ({
    isRequired: () => isRequired,
    getName: () => name,
    getValue: () => value,
    hasError: () => error,
    triggerError: (e) => setError(e),
    isValid: () => !checkStatus()
  }))

  return (
    <Container>
      <Wrapper error={ error } onClick={ () => handleChange() } tabIndex={0}>
        <InputWrapper>
          <Input active={ value } />
        </InputWrapper>
        <LabelWrapper>
          <Label>
            { children }
          </Label>
        </LabelWrapper>
      </Wrapper>
    </Container>
  )
})

Checkbox.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  name: PropTypes.string
}

export default Checkbox
