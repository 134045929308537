import styled from '@emotion/styled'

import { mediaMax, sectionTitle, styledScrollbar } from '@/styles/mixins'

export const DropDownLabel = styled.label`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ state, theme }) =>
    state ? theme.colors.brown : theme.colors.grey};
  transition: opacity ease-out 0.3s;
`
export const Label = styled.label`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ state, theme }) =>
    state ? theme.colors.brown : theme.colors.grey};
  transition: opacity ease-out 0.3s;
`

export const IconWrapper = styled.div`
  padding-top: 5px;
  transition: transform ease 0.3s;
  transform: rotate(${({ state }) => (state ? '-180deg' : '0deg')});
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;
`
export const Placeholder = styled.span`
  font-weight: bold;
  font-family: ${(p) => p.theme.fonts.sansSerif};
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.black};
`

export const Wrapper = styled.div`
  width: 100%;
  padding: 21px 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  cursor: pointer;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.redError : `${theme.colors.grey}30`};
  text-decoration: none;
  transition: background-color ease-out 0.3s;
  ${({ error, theme }) =>
    error && `${DropDownLabel} { color: ${theme.colors.redError};}`}

  ${mediaMax.xs} {
    padding: 1.4rem 67px;
  }
`

export const DropDownWrapper = styled.div`
  position: absolute;
  z-index: 12;
  top: 100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.lightVanilla};
  border: 1px solid ${({ theme }) => `${theme.colors.grey}30`};
  overflow-x: hidden;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  transition: opacity ease 300ms;
  ${mediaMax.xs} {
    top: calc(100% - 1px);
  }
  ${styledScrollbar()}
`

export const DropDownList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const DropDownItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 6px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightVanilla};
  transition: background-color ease-out 300ms;
  &:hover {
    background-color: ${({ theme }) => theme.colors.darkVanilla};
  }
`

export const DropDownItemLabel = styled.span`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ active, theme }) =>
    active ? theme.colors.brown : theme.colors.grey};
`

export const DropDownItemIcon = styled.span`
  margin-left: 5px;
`

export const DropDownSelectItem = styled.option`
  display: none;
`

export const DropDownSelect = styled.select`
  display: none;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightVanilla};
  border-radius: 10px;
  position: relative;

  &.separation {
    ${DropDownItem}:nth-of-type(4) {
      padding-bottom: 0;
      ${DropDownItemLabel} {
        border-bottom: solid ${(props) => props.theme.colors.lightGrey} 1px;
        width: 90%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding-bottom: 1.5em;
      }
    }
    ${DropDownItem}:nth-of-type(5) {
      padding-top: 2em;
    }
  }

  &.withoutStyle {
    background: none;

    ${DropDownLabel} {
      font-size: 1rem;
      color: ${(props) => props.theme.colors.grey};
    }

    ${DropDownWrapper}, ${DropDownItem} {
      background-color: ${(props) => props.theme.colors.white};
    }

    ${Wrapper} {
      border: none;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 50%;
        background-color: ${(props) => props.theme.colors.grey};
      }

      ${mediaMax.xs} {
        padding-right: 0;
      }
    }
  }

  &.isSlideSelector {
    background: none;

    ${DropDownLabel} {
      display: block;
      position: relative;
      ${sectionTitle()}
      font-size: 4rem;
      color: ${(props) => props.theme.colors.green};
      text-transform: inherit;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.colors.green};
      }
    }

    ${DropDownWrapper}, ${DropDownItem} {
      background-color: ${(props) => props.theme.colors.white};
    }

    ${DropDownItemLabel} {
      text-transform: inherit;
    }

    ${Wrapper} {
      border: none;
      position: relative;
      padding: 0;
      justify-content: flex-start;
      margin-bottom: 3rem;
      // margin-top: -4rem;

      ${mediaMax.xs} {
        padding-right: 0;
      }
    }

    svg {
      position: relative;
      bottom: 5px;
    }

    path {
      fill: ${(props) => props.theme.colors.green} !important;
    }
  }
`
