import * as React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ titleColor = '', subColor = '' }) => {
  return (
    <>
      <svg alt='Cloudy Bay New Zealand Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region' title='Cloudy Bay New Zealand Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 417.52 70.88'>
        <defs>
          <linearGradient
            x1='39.19' y1='64.1' x2='377.03' y2='64.1'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.17' stopColor='#cc9549' />
            <stop offset='0.26' stopColor='#cc9549' />
            <stop offset='0.37' stopColor='#efce90' />
            <stop offset='0.54' stopColor='#cc9549' />
            <stop offset='0.62' stopColor='#deb26d' />
            <stop offset='0.69' stopColor='#efce90' />
            <stop offset='0.89' stopColor='#cc9549' />
            <stop offset='1' stopColor='#cc9549' />
          </linearGradient>
          <linearGradient x1='39.59' y1='64.1' x2='379.45' y2='64.1' />
          <linearGradient
             x1='37.49' y1='64.13' x2='379.47' y2='64.13'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.17' stopColor='#cc9549' />
            <stop offset='0.26' stopColor='#cc9549' />
            <stop offset='0.37' stopColor='#efce90' />
            <stop offset='0.54' stopColor='#cc9549' />
            <stop offset='0.62' stopColor='#deb26d' />
            <stop offset='0.69' stopColor='#efce90' />
            <stop offset='0.89' stopColor='#cc9549' />
          </linearGradient>
          <linearGradient
            x1='38.46' y1='64.1' x2='378.81' y2='64.1' />
          <linearGradient x1='37.59' y1='64.1' x2='382.58' y2='64.1' />
          <linearGradient x1='37.74' y1='64.06' x2='380.36' y2='64.06' />
          <linearGradient x1='36.8' y1='64.1' x2='379.96' y2='64.1' />
          <linearGradient x1='36.54' y1='64.06' x2='380.81' y2='64.06' />
          <linearGradient x1='36.67' y1='64.1' x2='381.43' y2='64.1' />
          <linearGradient x1='36.5' y1='64.1' x2='381.07' y2='64.1' />
        </defs>
        <g>
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M265.81,2.38c-.88,0-2.83.05-4.77.05s-3.5,0-4.5-.05h-.65a.75.75,0,0,0-.83.78.74.74,0,0,0,.59.69l.77.21c.66.18,1.42.39,2,.58a1.55,1.55,0,0,1,1,.73,1.13,1.13,0,0,1-.09.9,133.78,133.78,0,0,1-6.64,11.94c-2.3-3.78-6-9.25-7.76-12.2a1,1,0,0,1,0-.92,1.33,1.33,0,0,1,.88-.76c.42-.1.76-.15,1.14-.21s.81-.13,1.39-.26a.73.73,0,0,0,.6-.7.75.75,0,0,0-.83-.78h-.63c-1.16,0-3.71,0-5.86,0-2.35,0-4.51,0-5.49,0h-.52a.76.76,0,0,0-.84.78.8.8,0,0,0,.63.71l.25.06c1,.23,3.62.83,4.78,2.78l.77,1.3c1.85,3.12,4.38,7.39,7.91,13,0,3.5,0,8.24-.12,12a2.5,2.5,0,0,1-2.15,2.53c-.55.1-1,.16-1.51.22s-.93.12-1.45.21a.81.81,0,0,0-.62.74.81.81,0,0,0,.84.86h.68c1.16,0,3.56-.08,6.34-.08s5.2,0,6.37.08h.71a.81.81,0,0,0,.21-1.6c-.53-.1-1.1-.17-1.64-.23s-1-.12-1.46-.2a2.73,2.73,0,0,1-2-2.4c-.13-3.83-.13-9-.13-13.21.9-1.55,4.09-6.74,6.18-10,1.23-1.9,1.75-2.66,2.46-3.67a4.57,4.57,0,0,1,1.89-1.52c.83-.31,1.31-.45,1.74-.57l.71-.22.3-.1c.38-.11.78-.23.78-.67s-.33-.78-.88-.78h-.47'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M60.85,2.3c-1.43,0-3.59.05-6.41.05s-5,0-6.34-.05h-.17a.75.75,0,0,0-.83.78c0,.32.24.57.64.66,3.28.67,4.62,1.66,4.62,3.45s0,3.9,0,6,.05,4.45.05,6.64c0,1,0,2.14,0,3.31,0,3.07,0,6.54-.07,9.29v.13c0,.69,0,2.8-4.2,3.34a.77.77,0,0,0-.64.74.82.82,0,0,0,.83.86c.21,0,.46,0,.75-.06s.82-.09,1.38-.1c1.29,0,2.53,0,3.72,0a111.69,111.69,0,0,1,18.32,1.23l.48.09.39.09a2.14,2.14,0,0,0,.43.07.59.59,0,0,0,.33-.1.48.48,0,0,0,.19-.39c0-.42,0-1,0-1.7s0-1.71,0-2.46c0-2.18,0-3.11.06-4.39V29.5a.66.66,0,0,0-.17-.48.56.56,0,0,0-.88.12c0,.1-.34,1.25-.54,1.68C71.2,34.59,69.49,36,59.52,36c-1.87,0-2.82-.74-2.92-2.26-.1-3.88-.1-9.87-.1-13.89,0-2,0-4.2.06-6.36s.06-4.15.06-6c0-2,1.48-3.25,4.4-3.69.51-.08.82-.33.85-.67a.71.71,0,0,0-.18-.55A.89.89,0,0,0,61,2.3Z'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M416.16,2.38c-.87,0-2.83.05-4.77.05s-3.5,0-4.49-.05h-.65a.75.75,0,0,0-.83.78.74.74,0,0,0,.58.69l.77.21c.67.18,1.42.39,2,.58a1.61,1.61,0,0,1,1,.73,1.1,1.1,0,0,1-.1.9A131.26,131.26,0,0,1,403,18.2C400.69,14.42,397,9,395.24,6a1,1,0,0,1,0-.92,1.33,1.33,0,0,1,.88-.76c.42-.1.76-.15,1.13-.21s.81-.13,1.4-.26a.73.73,0,0,0,.6-.7.75.75,0,0,0-.84-.78h-.62c-1.17,0-3.71,0-5.86,0-2.36,0-4.52,0-5.49,0h-.53a.76.76,0,0,0-.83.78.79.79,0,0,0,.63.71l.25.06c1,.23,3.62.83,4.77,2.78l.77,1.3c1.85,3.12,4.38,7.39,7.92,13,0,3.5,0,8.24-.13,12a2.49,2.49,0,0,1-2.15,2.53c-.54.1-1,.16-1.5.22s-.94.12-1.45.21a.79.79,0,0,0-.62.74.81.81,0,0,0,.84.86h.68c1.15,0,3.55-.08,6.33-.08s5.21,0,6.38.08h.71a.81.81,0,0,0,.21-1.6c-.54-.1-1.1-.17-1.64-.23s-1-.12-1.46-.2a2.71,2.71,0,0,1-2-2.4c-.14-3.83-.14-9-.14-13.21.91-1.55,4.09-6.74,6.18-10,1.24-1.9,1.75-2.66,2.46-3.67A4.54,4.54,0,0,1,414,4.71c.83-.31,1.31-.45,1.73-.57l.72-.22.3-.1c.38-.11.78-.23.78-.67s-.33-.78-.89-.78h-.47'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M201.68,2.35l-.75.06a45,45,0,0,1-4.93.25c-1.19,0-2.43,0-3.7-.12l-1.25-.08-.47,0a.81.81,0,0,0-.84.85.88.88,0,0,0,.68.75l.77.19.79.19c1.75.43,2.44,1.07,2.47,2.3.08,3.68.16,9.07.16,13.21,0,3.85,0,9.66-.12,13.56,0,1-.66,1.55-2.49,2a13.51,13.51,0,0,1-1.52.34.81.81,0,0,0-.66.72.76.76,0,0,0,.79.83,7.86,7.86,0,0,0,1.42,0c.88-.07,2.12-.09,3.44-.11h1.13c1.2,0,2.76.12,4.27.24s2.72.21,3.65.21c9.75,0,17.11-7.78,17.11-18.09,0-10.74-6.56-17.41-17.11-17.41-.61,0-1.64.07-2.84.15m15.5,17.26c0,10.15-5,16.7-13.39,16.7a29.09,29.09,0,0,1-4-.38l-.91-.13c-.18-3.84-.18-11.53-.18-15.88s0-12.09.18-15.95a38.68,38.68,0,0,1,4.95-.38c9.25,0,13.39,8.28,13.39,16'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M357,0a.75.75,0,0,0-.66.48c-.34.81-.52,1.06-1.84,2.19l-.1.09c-.4.32-.73.62-.73,1a1,1,0,0,0,.38.71,2.65,2.65,0,0,1,.59.82c.51,1.09,1,2.26,1.51,3.38l.28.64c-.46,1.05-1,2.52-1.89,4.88-.36,1-.76,2-1.17,3.08-1.84,4.85-4.54,11.82-6.71,16.31-.24.47-1.24,1.68-5.52,2.58a.79.79,0,0,0-.61.73.75.75,0,0,0,.84.79l.59,0c1,0,2.7-.14,5.2-.14,2.88,0,5.27,0,6.29.08H354a.84.84,0,0,0,.85-.87.81.81,0,0,0-.63-.74c-.48-.09-1-.15-1.56-.22s-1.37-.16-2.14-.31a1.64,1.64,0,0,1-1.2-.63,2.1,2.1,0,0,1,0-1.64c.74-2.51,1.5-5.06,2-6.47h12.18c.82,2,1.61,4.15,2.31,6.2a2,2,0,0,1,0,1.59c-.3.52-1.06.91-2.42,1.22l-1.76.4a.83.83,0,0,0-.61.74.76.76,0,0,0,.83.79l.67,0c1.14,0,3.51-.13,6.49-.13,2.67,0,4.72,0,5.65,0h.52a.76.76,0,0,0,.84-.79.82.82,0,0,0-.57-.73,6.25,6.25,0,0,0-.85-.23l-.13,0a4.83,4.83,0,0,1-3.88-3.16c-1.17-2.64-2.27-5.29-3.34-7.86-1-2.3-1.94-4.67-3-7-1.8-4.34-5.55-13.83-6.62-17.32a.65.65,0,0,0-.6-.44Zm3.71,19.76c.69,1.69,1.39,3.88,2.05,5.53H351.6c.87-2.42,4.21-10.79,5.57-14.22,1,2.51,2.21,5.52,3.5,8.69'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M313.3,2.38l-.42,0c-1.48.13-3,.23-4,.26h-.82c-1.58,0-3.24-.09-4.34-.14h-.1l-1.12-.06-.43,0a.81.81,0,0,0-.84.85.87.87,0,0,0,.69.75l.76.19.79.19a2.91,2.91,0,0,1,2.47,2.31c.13,4.14.16,9.49.16,11.59v1.6c0,3.09,0,6.93-.05,10.3,0,1.2,0,2.35-.07,3.36,0,.68-.9,1.62-2.49,2.05A15.3,15.3,0,0,1,302,36a.8.8,0,0,0-.65.72c0,.51.26.79.84.84l.22,0c.17,0,.47-.07,1.14-.1.51,0,1.2,0,1.8,0h3c.68,0,1.73,0,2.94.12l3.08.15c.89.05,1.71.08,2.3.08,6,0,12.38-3.55,12.38-10.15,0-4.23-2.75-6.95-7.75-7.72,3.36-1.59,6.87-4.1,6.87-9,0-6.34-5.64-8.59-10.47-8.59-1,0-2.62.09-4.36.18m10.59,9.69c0,6.44-6.61,7.27-12.37,7.27h-1.37c0-4,0-11.56.18-15.3a23.63,23.63,0,0,1,4.5-.45c4.51,0,9.06,3,9.06,8.48m.35,16.33c0,5.13-4.47,7.9-8.89,7.9a38.22,38.22,0,0,1-5-.46c0-.36,0-1.24-.05-1.65-.12-3.83-.13-9.85-.13-13h1.37c4.76,0,12.72,0,12.72,7.2'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M89.54,20.56c0,10,7.42,17.57,17.27,17.57,10.46,0,18-7.91,18-18.81,0-10-7.43-17.56-17.28-17.56-10.45,0-18,7.91-18,18.8m32.89,2.58c0,7.66-6.2,12.8-12,12.8C102.34,35.94,92,27,92,16.76,92,10.71,96.88,4,104,4c8.1,0,18.48,8.92,18.48,19.17'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M153,2.35c-1.43,0-3.59,0-6.41,0s-4.93,0-6.23,0h-.16c-.82,0-.91.49-.91.71a.86.86,0,0,0,.68.73c.58.15.72.19,1.31.38l.21.06c2.73.85,3,2.31,3,3.33,0,1.89,0,4,0,6.18s0,4.27,0,6.14c0,8.67,0,11.91,3.08,14.75A12.77,12.77,0,0,0,157.11,38a12.52,12.52,0,0,0,8.6-3.19c3.83-3.15,3.83-11.7,3.83-18.57,0-2.57.27-6.42.5-8.94.16-1.74.84-2.75,4.09-3.48.28-.06.93-.26.93-.8,0-.16-.06-.71-.86-.71H174c-1.32,0-2.8,0-5.37,0s-4.49,0-5.74,0h-.15a.73.73,0,0,0-.8.76c0,.66.81.74,1.07.77,2.06.22,4.51,1.16,4.64,3.08,0,.48.08,1.12.14,1.85.18,2.32.43,5.48.43,7.47,0,6.93,0,14.1-3.26,17.17a12.36,12.36,0,0,1-7.89,3.19,8.19,8.19,0,0,1-7.2-4c-1.24-2-1.24-6.9-1.24-10.46,0-2,0-8.74.08-13.86V8c0-.23,0-.45,0-.67,0-1.83,1.75-3.2,4.46-3.51.32,0,.81-.26.81-.72,0-.27-.1-.72-.83-.72Z'
          />
          <path
            className={ !titleColor ? 'cls-1' : '' } fill={ titleColor }
            d='M26.47,2.08A4.73,4.73,0,0,1,26,3a1.88,1.88,0,0,1-1.76,1,10.82,10.82,0,0,1-3.07-.76A16.09,16.09,0,0,0,16,2.12C6.58,2.12,0,9.77,0,20.73,0,31,6.68,38.14,16.24,38.14c6.32,0,10.62-3.69,11.61-5.36a1.44,1.44,0,0,0,.26-.71.73.73,0,0,0-.75-.7c-.31,0-.59.28-1.09.78a10.24,10.24,0,0,1-7.76,3.43C10.39,35.58,3.77,27.24,3.77,17c0-6.45,3.61-13.44,11.65-13.44,3.3,0,5.45,1.51,7.11,2.52S26,10,26.1,13.48c0,.73.36,1.22.84,1.22s.84-.53.84-1.23c0-.28,0-3.15,0-5.85,0-2.41,0-4.69,0-4.89,0-.46,0-1.22-.78-1.22-.36,0-.47.31-.57.57'
          />
          <polygon
            className={ !subColor ? 'cls-2' : '' } fill={ subColor }
            points='47.63 66.66 40.47 57.42 38.29 57.42 38.29 70.78 40.6 70.78 40.6 61.28 47.97 70.78 49.94 70.78 49.94 57.42 47.63 57.42 47.63 66.66'
          />
          <polygon
            className={ !subColor ? 'cls-3' : '' } fill={ subColor }
            points='74.79 65.09 81.49 65.09 81.49 63 74.79 63 74.79 59.52 82.35 59.52 82.35 57.42 72.44 57.42 72.44 70.78 82.44 70.78 82.44 68.69 74.79 68.69 74.79 65.09'
          />
          <polygon
            className={ !subColor ? 'cls-4' : '' } fill={ subColor }
            points='117.55 67.33 114.27 57.38 112.28 57.38 109 67.33 105.81 57.42 103.27 57.42 107.93 70.88 109.95 70.88 113.24 61.28 116.52 70.88 118.56 70.88 123.2 57.42 120.74 57.42 117.55 67.33'
          />
          <polygon
            className={ !subColor ? 'cls-5' : '' } fill={ subColor }
            points='169.24 59.5 176.88 59.5 168.99 69.05 168.99 70.78 179.95 70.78 179.95 68.7 172.05 68.7 179.95 59.16 179.95 57.42 169.24 57.42 169.24 59.5'
          />
          <polygon
            className={ !subColor ? 'cls-6' : '' } fill={ subColor }
            points='204.29 65.09 210.99 65.09 210.99 63 204.29 63 204.29 59.52 211.85 59.52 211.85 57.42 201.94 57.42 201.94 70.78 211.94 70.78 211.94 68.69 204.29 68.69 204.29 65.09'
          />
          <path
            className={ !subColor ? 'cls-7' : '' } fill={ subColor }
            d='M238.54,57.33l-5.88,13.45h2.4l1.38-3.22h6.31l1.36,3.22h2.48l-5.88-13.45Zm-1.25,8.15,2.3-5.35,2.31,5.35Z'
          />
          <polygon
            className={ !subColor ? 'cls-8' : '' } fill={ subColor }
            points='270.3 57.42 267.95 57.42 267.95 70.78 277.32 70.78 277.32 68.65 270.3 68.65 270.3 57.42'
          />
          <path
            className={ !subColor ? 'cls-9' : '' } fill={ subColor }
            d='M303.57,57.33l-5.88,13.45h2.41l1.37-3.22h6.32l1.36,3.22h2.48l-5.88-13.45Zm-1.24,8.15,2.29-5.35,2.31,5.35Z'
          />
          <polygon
            className={ !subColor ? 'cls-10' : '' } fill={ subColor }
            points='342.32 66.66 335.16 57.42 332.99 57.42 332.99 70.78 335.3 70.78 335.3 61.28 342.66 70.78 344.63 70.78 344.63 57.42 342.32 57.42 342.32 66.66'
          />
          <path
            className={ !subColor ? 'cls-11' : '' } fill={ subColor }
            d='M372.12,57.42h-5V70.78h5c4.15,0,7-2.86,7.1-6.56V64C379.16,60.25,376.27,57.42,372.12,57.42Zm4.64,6.72a4.36,4.36,0,0,1-4.64,4.51h-2.64V59.56h2.64a4.38,4.38,0,0,1,4.64,4.54Z'
          />
        </g>
      </svg>
    </>
  )
}

Logo.propTypes = {
  titleColor: PropTypes.string,
  subColor: PropTypes.string
}

export default Logo
