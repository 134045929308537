import React, { useState, useEffect } from 'react'
import Logo from '@/components/Logo'
import AgeForm from '@/components/Forms/AgeForm'

import { useRecoilValue } from 'recoil'
import { countryState } from '@/recoil/ageGate'

import countriesData from '@/config/legal_age.json'
import PropTypes from 'prop-types'

import { useTheme } from '@emotion/react'
import {
  Container,
  Wrapper,
  LogoBgWrapper,
  LogoWrapper,
  LogoTitle,
  Content,
  LegalsWrapper,
  Legals,
  TextLink
} from './style'

const AgeGate = ({ onSuccess = () => {} }) => {
  const { colors } = useTheme()
  const countryName = useRecoilValue(countryState)
  const [baseUrl, setBaseUrl] = useState('/en-ww')

  const country = countriesData.countries.find((country) => country.label === countryName)

  useEffect(() => {
    setBaseUrl(country?.market ? `/${country?.market}` : '/en-ww')
  }, [country])

  const marketCodesList = [
    {
      code: 'en-gb',
      label: 'Great Britain'
    },
    {
      code: 'en-au',
      label: 'Australia'
    },
    {
      code: 'en-us',
      label: 'USA'
    },
    {
      code: 'en-nz',
      label: 'New Zealand'
    },
    {
      code: 'en-ww',
      label: 'International'
    }
  ]

  return (
    <Container>
      <Wrapper>
        <LogoBgWrapper>
          <Logo titleColor={ colors.lightGrey } subColor={ colors.lightGrey } />
        </LogoBgWrapper>
        <LogoWrapper title='Cloudy Bay New Zealand, Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region'>
          <Logo titleColor={ colors.white } subColor={ colors.white } />
          <LogoTitle>Cloudy Bay New Zealand, Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region</LogoTitle>
        </LogoWrapper>
        <Content>
          <AgeForm
            onSuccess={ () => onSuccess() }
          />
        </Content>
        <LegalsWrapper>
          <Legals>
            To visit the Cloudy Bay website, you must be of legal drinking and purchasing age in your country/region. If there is no legal age for consuming alcohol,
            you must be over 21.
            <br />
            By entering this site you acknowledge that you accept its <TextLink to={ baseUrl + '/terms-of-use' } target='_blank' title='terms and conditions'>terms and conditions</TextLink> of use and have read our <TextLink to={ baseUrl + '/privacy-cookies-notice' } target='_blank' title='privacy cookies policy'>privacy cookies policy</TextLink>.
            The abuse of alcohol is dangerous for your health. Drink responsibly.
            Cloudy Bay supports the responsible consumption of wines and spirits, through Moët Hennessy,
            member of <TextLink to='https://spirits.eu/' target='_blank' title='Spirits EUROPE'>Spirits EUROPE</TextLink>, <TextLink to='https://www.distilledspirits.org/' target='_blank' title='Discus'>Discus</TextLink> and <TextLink to='https://www.wineinmoderation.eu/' target='_blank' title='Wine in Moderation'>Wine in Moderation</TextLink>.
          </Legals>
          <noscript>
            <div>
              {marketCodesList.map((marketCode, i) => (
                <a key={ `market-${i}` } href={ `/${marketCode.code}/` }>{marketCode.label}</a>
              ))}
            </div>
          </noscript>
        </LegalsWrapper>
      </Wrapper>
    </Container>
  )
}

AgeGate.propTypes = {
  onSuccess: PropTypes.func
}

export default AgeGate
